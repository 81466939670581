import * as React from 'react';
import {
  Button,
  Card,
  DisplayText,
  FormLayout,
  Link,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext } from 'react';
import { ToastContext } from '../../providers/ToastContext';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(2).max(50).required(),
});

const LoginPage: React.FC = () => {
  const { show } = useContext(ToastContext);
  const router = useRouter();
  const { mutate } = useSWRConfig();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await login(values.email, values.password);
    },
  });

  const login = async (email: string, password: string) => {
    // Hit the API to validate the token
    const response = await fetch(`${process.env.APP_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const { message, token } = await response.json();
    if (response.status >= 400) {
      return show({ isError: true, message: message });
    }

    localStorage.setItem('tuneboomAccessToken', token);
    await mutate(`${process.env.APP_URL}/users`);

    return router.push('/dashboard');
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const handleChange = (value, id) => formik.handleChange({ target: { id, value } });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Stack vertical alignment={'center'}>
          <img style={{ width: '160px' }} src={'/static/images/tuneboom-logo-dark.svg'} />
        </Stack>
        <div style={{ margin: '25px 50px' }}>
          <Card sectioned>
            <div style={{ padding: '20px 30px', minWidth: 400 }}>
              <Stack vertical spacing={'loose'}>
                <Stack distribution={'center'}>
                  <DisplayText size={'small'}>Sign in to your account</DisplayText>
                </Stack>
                <FormLayout>
                  <TextField
                    id="email"
                    label="Email"
                    value={formik.values.email}
                    type="text"
                    onChange={handleChange}
                    error={formik.errors.email && formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    id="password"
                    label="Password"
                    value={formik.values.password}
                    type="password"
                    labelAction={{
                      content: 'Forgot your password?',
                      onAction: () => router.push('/forgot-password'),
                    }}
                    onChange={handleChange}
                    error={
                      formik.errors.password && formik.touched.password && formik.errors.password
                    }
                  />
                </FormLayout>

                <Button
                  fullWidth
                  primary
                  onClick={formik.handleSubmit}
                  loading={formik.isSubmitting}
                >
                  Continue
                </Button>
              </Stack>
            </div>
          </Card>
        </div>
        <TextStyle>
          Don't have an account yet? <Link onClick={() => router.push('/signup')}>Sign up</Link>
        </TextStyle>
      </div>

      <div className={'image-container'}>
        {/*  <img
          className={'hero__background-image'}
          src={
            'https://assets.nflxext.com/ffe/siteui/vlv3/b8e09d9c-d1e7-4800-afd9-810e41ace684/283f6c07-e0f3-4a4a-81c9-4651a6a6f82a/CA-en-20210607-popsignuptwoweeks-perspective_alpha_website_large.jpg'
          }
        />*/}
        <div className={'overlay'}>
          <img
            style={{ width: '100px', bottom: 0, right: 0, position: 'absolute' }}
            src={'/static/images/white_logomark.svg'}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
